<template>
    <v-select class="selector year-selector" :clearable="false" :searchable="false" :options="availableYears" :value="$store.state.yearFilter"  @input="changeYear" v-on:search:focus="fixHighlight">
        <span slot="no-options">
            Ano não arquivado!
        </span>
    </v-select>
</template>

<script>
import 'vue-select/dist/vue-select.css'

import store from '@/store'
import {shuffle} from '@/util'

export default {
    props: {
        allowSearch: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        availableYears() {
            return ['Todos'].concat(this.$store.state.metadata.years)
        }
    },
    methods: {
        async changeYear(year) {
            await store.dispatch('setYearFilter', year)

            if (year === 'Todos')
                this.generateYearAnimation()

            await store.dispatch('populateArticles')
        },
        generateYearAnimation() {
            // we edit the vue-select element directly instead of using slots
            // slots needed an intermediate elem and that could originate an inconsistent css styling
            const elem = document.querySelector('.year-selector .vs__selected')

            try {
                $(elem).text('')
                const years = shuffle(store.state.rng, Object.assign([], this.$store.state.metadata.years))

                let typeIt = new TypeIt(elem, {
                    speed: 50,
                    deleteSpeed: 80,
                    waitUntilVisible: true,
                    loop: years.length > 1
                })

                if (years.length > 1) {
                    for (let i = 0; i < years.length; i++)
                        typeIt = typeIt.type(years[i]).pause(1500).delete(4).pause(500)
                } else {
                    typeIt = typeIt.type(years[0])
                }

                typeIt.go()
            } catch (err) {
                // fallback
                $(elem).text('...')
            }
        },
        fixHighlight () {
            this.$nextTick(() => {
                $('.year-selector .vs__dropdown-option.vs__dropdown-option--highlight').removeClass('vs__dropdown-option--highlight')
                $('.year-selector .vs__dropdown-option.vs__dropdown-option--selected').addClass('vs__dropdown-option--highlight')
            })
        }
    },
    mounted() {//vs__dropdown-option--highlight
        this.generateYearAnimation()
    }
}
</script>

<style>
.year-selector {
    width: 10rem;
    display: flex;
    align-items: baseline;
}

/*#year-selector .vs__search {*/
/*    display: none;*/
/*}*/
</style>
