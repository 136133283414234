<template>
<div id="app">
    <DummyHeader class="pb-1 mb-5 mb-sm-3 mb-md-2" />
    <Header class="pb-1 mb-5 mb-sm-3 mb-md-2" />

    <div class="spinner spinner-border" v-if="$store.state.isTransitioning" style="width: 5rem; height: 5rem; color: #bf2651" role="status">
        <span class="sr-only">A carregar...</span>
    </div>

    <router-view class="mb-5" />

    <footer class="bg-title text-white">
        <div class="container">
            <div class="row h-100 align-items-center">
                <div class="col-12 my-2">
                    <span class="pr-1">2021 - Destaques do Passado</span>
                    ·
                    <span :class="{'underline': $route.name !== 'home'}">
                        <router-link :to="{name: 'home'}" class="text-white" :is="$route.name === 'home' ? 'span' : 'router-link'">Início</router-link>
                    </span>
                    ·
                    <span :class="{'underline': $route.name !== 'about'}">
                        <router-link :to="{name: 'about'}" class="text-white" :is="$route.name === 'about' ? 'span' : 'router-link'">Sobre</router-link>
                    </span>
                    ·
                    <span :class="{'underline': $route.name !== 'api'}">
                        <router-link :to="{name: 'api'}" class="text-white" :is="$route.name === 'api' ? 'span' : 'router-link'">API</router-link>
                    </span>
                    ·
                    <span :class="{'underline': $route.name !== 'stats'}">
                        <router-link :to="{name: 'stats'}" class="text-white" :is="$route.name === 'stats' ? 'span' : 'router-link'">Estatísticas</router-link>
                    </span>
                    ·
                    <span class="text-white underline clickable" @click="openShareModal">
                        Partilhar
                    </span>
                </div>
            </div>
        </div>
    </footer>
    <ShareModal />
</div>
</template>

<script>
import '@/assets/generic.css'
import Header from '@/components/Header'
import DummyHeader from '@/components/DummyHeader'
import store from '@/store'
import {MONTH_DURATIONS} from '@/util'
import ShareModal from '@/components/ShareModal'

export default {
    components: {
        ShareModal,
        DummyHeader,
        Header
    },
    computed: {
        selectedDay() {
            return store.state.selectedDay
        },
        selectedMonth() {
            return store.state.selectedMonth
        }
    },
    methods: {
        openShareModal() {
            $('#share-modal').modal('show')
        },
        async dayBack() {
            let day
            let month

            // if we need to go a month back
            if (this.selectedDay === 1) {
                // if we are in january go to december
                if (this.selectedMonth === 0)
                    month = 11
                else
                    month = this.selectedMonth - 1

                // now get the last day of the month
                day = MONTH_DURATIONS[month]
            } else {
                day = this.selectedDay - 1
                month = this.selectedMonth
            }

            await store.dispatch('selectDate', { month: month, day: day })
            await store.dispatch('populateArticles')
        },
        async dayForward() {
            let day
            let month

            // if we need to go a month forward
            if (this.selectedDay === MONTH_DURATIONS[this.selectedMonth]) {
                // if we are in december go to january
                if (this.selectedMonth === 11)
                    month = 0
                else
                    month = this.selectedMonth + 1

                day = 1
            } else {
                day = this.selectedDay + 1
                month = this.selectedMonth
            }

            await store.dispatch('selectDate', { month: month, day: day })
            await store.dispatch('populateArticles')
        },
        async refresh() {
            await store.dispatch('populateArticles')
        },
        handleKeyboard(e) {
            if (!e.shiftKey)
                return

            if (store.state.isTransitioning)
                return

            switch (e.keyCode) {
            case 37:
                this.dayBack()
                break
            case 39:
                this.dayForward()
                break
            case 13:
                this.refresh()
                break
            }
        }
    },
    created() {
        window.addEventListener('keydown', this.handleKeyboard)

        const seed = 'versao' in this.$route.query? this.$route.query['versao'] : null
        store.dispatch('setRNG', seed)
    },
    destroyed() {
        window.removeEventListener('keydown', this.handleKeyboard)
    }
}
</script>

<style>
/* MODAL */
.modal-open {
    padding-right: 0 !important;
    overflow: inherit !important;
}

.modal-open header {
    padding-right: 0 !important;
}

/* modal over header*/
.modal-backdrop {
    z-index: 2500;
}

/* progress over header */
#nprogress .bar, #nprogress .spinner {
    z-index: 2400;
}

/* tooltip over header and modal*/
.tooltip {
    z-index: 4000;
}

.spinner {
    display: block;
    position: fixed;
    z-index: 2400; /* progress over header */
    top: calc( 50% - ( 5rem / 2) );
    right: calc( 50% - ( 5rem / 2) );
}


/* FOOTER */
html {
    position: relative;
    min-height: 100%;
}

body {
    /* Margin bottom by footer height */
    margin-bottom: 150px;
}

@keyframes footerAppear {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 150px;

    /* make footer appear slowly so it doesn't jump at beginning */
    animation: 3s ease-out 0s 1 footerAppear;
}

footer .container {
    height: 100%;
}
</style>
