<template>
<div ref="button" class="text-bigger">
    <router-link :to="{name: 'about'}" :class="isDark? 'text-white' : 'text-muted'"><i class="far fa-question-circle"></i></router-link>
</div>
</template>

<script>

import button from '@/mixins/button'

export default {
    mixins: [
        button
    ],
    data() {
        return {
            tooltip: 'Sobre o Destaques do Passado'
        }
    }
}
</script>

<style scoped>
.text-bigger {
    font-size: 110%;
}
</style>
