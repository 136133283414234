import inViewport from 'vue-in-viewport-mixin'
import store from '@/store'

export default {
    mixins: [
        inViewport
    ],
    props: {
        articleList: {
            type: Array
        },
        sectionName: {
            type: String
        },
        showDelay: {
            type: Number
        },
        waitForViewport: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        'inViewport.now': function (visible) {
            if (visible)
                this.hasEnteredViewport = true

            // only animate after parent said so
            if (this.waitForViewport && this.hasEnteredViewport && this.allAreMounted)
                this.showMyself()
        }
    },
    data() {
        return {
            hasEnteredViewport: false,
            allAreMounted: false
        }
    },
    methods: {
        mountConfirmation(id) {
            // whenever a child is mounted this function is called
            this.articleList.filter(a => a.identifier === id)[0].mounted = true

            // when all are mounted order to show sequentially
            if (this.articleList.every(e => e.mounted === true)) {
                this.allAreMounted = true

                // animate in case we're not waiting for the viewport, or if we've been seen but havent't animated for some reason
                if (!this.waitForViewport || this.hasEnteredViewport) {
                    this.showMyself()
                }
            }
        },
        showMyself() {
            // show all my children sequentially
            this.articleList.forEach((article, i) => {
                if (store.state.animateOnEnter) {
                    article.showTimeout = setTimeout(() => {
                        this.$root.$emit(`show-element-${article.identifier}`, true)
                    }, i * this.showDelay + 100) // +100 allows for a smoother animation
                } else {
                    this.$root.$emit(`show-element-${article.identifier}`, false)
                }
            })
        },
        hideMyself(animate) {
            this.articleList.forEach((article, i) => {
                if (article.showTimeout) {
                    clearTimeout(article.showTimeout)
                    article.showTimeout = null
                }

                if (animate) {
                    article.hideTimeout = setTimeout(() => {
                        this.$root.$emit(`hide-element-${article.identifier}`, animate)
                    }, i * 50)
                } else {
                    this.$root.$emit(`hide-element-${article.identifier}`, animate)
                }
            })
        }
    },
    created() {
        store.subscribe((mutation) => {
            if (mutation.type === 'SET_IS_TRANSITIONING' && mutation.payload)
                this.hideMyself(store.state.animateOnLeave) // hide articles before new ones come
        })
    }
}
