<template>
<div class="container">
    <div class="row mb-4 align-items-center">
        <div class="col-12">
            <ul class="nav h3">
                <li class="nav-item">
                    <router-link :to="{'name': 'home'}" class="nav-link pl-0">Início</router-link>
                </li>
                <li class="nav-item">
                    <a class="nav-link active pl-0">Sobre</a>
                </li>
                <li class="nav-item">
                    <router-link :to="{'name': 'api'}" class="nav-link pl-0">API</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{'name': 'stats'}" class="nav-link pl-0">Estatísticas</router-link>
                </li>
            </ul>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-9 text-justify">
            <h2>Sobre</h2>

            <p>
                O Destaques do Passado é um agregador de notícias históricas de Portugal, publicadas entre 1996 e 2016
                em diversos órgãos de comunicação social, e arquivadas no
                <a href="https://arquivo.pt" target="_blank">Arquivo.pt</a>.
            </p>
            <p>
                O agregador mostra notícias para um determinado dia do calendário, por omissão o dia corrente.
                Contudo, poderá escolher qualquer dia do calendário ("edição"), para ver as notícias desse dia.
            </p>

            <p>
                O agregador serve também como <i>frontend</i> para um <i>dataset</i> de mais de 510 mil notícias
                históricas, que poderão ser descarregadas livremente para análise de dados para fins científicos ou
                pessoais. O conteúdo destas notícias é propriedade do Arquivo.pt e/ou dos seus devidos autores.
            </p>

            <p>
                O Destaques do Passado foi criado em 2021 por
                <a href="https://guilhermeborges.net" target="_blank">Guilherme Borges</a>.
            </p>

            <p>
                Pode obter mais informações sobre o Destaques do Passado nas páginas
                <b><router-link :to="{'name': 'api'}">API</router-link></b> e
                <b><router-link :to="{'name': 'stats'}">Estatísticas</router-link></b>.

                O repositório foi gerado com código-aberto disponível no
                <a href="https://github.com/sgtpepperpt/destaques-do-passado" target="_blank">GitHub</a>.
            </p>
        </div>
        <div class="col-3 d-flex align-items-center justify-content-center">
            <a href="https://arquivo.pt" target="_blank">
                <img src="@/assets/logoarquivo.png" alt="Logótipo Arquivo.pt" class="img-fluid" />
            </a>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-12 text-justify">
            <h3>Pesquisa</h3>
            <p>
                A funcão de pesquisa permite encontrar artigos noticiosos de cada dia sobre um determinado termo de
                pesquisa, sendo procuradas correspondências no título, ante-título ou <i>lead</i>/excerto do corpo da
                notícia.
            </p>
            <p>
                Para pesquisar notícias de uma determinada fonte, deverá afixar <code>@</code> ao início da pesquisa.
                Por exemplo, <code>@Público</code> retorna apenas artigos do jornal <a href="https://publico.pt" target="_blank">Público</a>.
            </p>
            <p>
                Por omissão é usada <i>pesquisa difusa</i>, que é tolerante a gralhas e, por isso, retorna também
                palavras que sejam semelhantes, e não apenas iguais, ao pesquisado.

                Para encontrar apenas resultados que correspondam apenas ao termo de pesquisa, afixe <code>!</code>
                ao início do termo de pesquisa. Por exemplo, <code>!Natal</code> retorna apenas artigos que contenham
                a palavra <b>Natal</b>, mas não outras semelhantes, como <b>Nadal</b>.
            </p>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-12 text-justify">
            <h3>Atalhos</h3>

            <p>Para facilitar a navegação no sítio pode utilizar os seguintes atalhos de teclado:</p>
            <dl class="row">
                <dt class="col-sm-4"><kbd>Shift</kbd> + <kbd>←</kbd></dt>
                <dd class="col-sm-8">Dia anterior</dd>

                <dt class="col-sm-4"><kbd>Shift</kbd> + <kbd>→</kbd></dt>
                <dd class="col-sm-8">Dia seguinte</dd>

                <dt class="col-sm-4"><kbd>Shift</kbd> + <kbd>Enter</kbd></dt>
                <dd class="col-sm-8">Renovar as notícias da página</dd>
            </dl>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-12 text-justify">
            <h3 class="mb-3">Perguntas frequentes</h3>

            <p class="lead">Que notícias são apresentadas na página inicial? É possível ver mais?</p>
            <p class="mb-5">
                As notícias apresentadas são escolhidas aleatoriamente de entre o conjunto completo de notícias com a data
                escolhida (por omissão a data de hoje). Poderá ver um conjunto diferente de notícias carregando no
                botão de renovar (<i class="fa fa-sync" ></i>) no topo da página. Verá que irão aparecer notícias
                novas de cada vez, podendo porém haver repetições, particularmente em dias em que não existam muitas
                notícias com imagem e lead arquivado.
            </p>

            <p class="lead">Quais foram as fontes de notícias utilizadas?</p>
            <p class="mb-5">
                As fontes do Destaques do Passado dividem-se entre sítios de notícias propriamente ditos,
                como o do jornal <a href="https://publico.pt" target="_blank">Público</a>, ou agregadores de notícias,
                como o <a href="https://news.google.pt" target="_blank">Google Notícias</a>.

                Escolhemos as nossas fontes de modo a obter uma quantidade de notícias significativas, com a melhor
                distribuição temporal possível, e dando preferência a fontes com imagens e <i>leads</i> arquivados.
                Poderá consultar algumas estatísticas <router-link :to="{'name': 'stats'}">aqui</router-link>.
            </p>

            <p class="lead">Porque é que algumas imagens parecem não corresponder ao conteúdo?</p>
            <p class="mb-5">
                De facto, algumas imagens podem não corresponder à notícia original. Este facto é alheio ao
                Destaques do Passado, que apenas recolhe a imagem associada a uma notícia conforme consta na versão
                do Arquivo.pt. Para verificar a proveniência dos conteúdos, use a ligação "Fonte" junto de cada
                notícia.
            </p>

            <p class="lead">Alguns resultados da pesquisa não correspondem ao que pretendia</p>
            <p class="mb-5">
                O método de <i>pesquisa difusa</i> utilizado, sendo tolerante a gralhas e erros ortográficos,
                também pode retornar palavras semanticamente não relacionadas. Porém, os resultados são apresentados
                por ordem de relevância descendente, pelo que os mais relevantes para a pesquisa aparecerão primeiro.
            </p>

            <p class="lead">Quais são os artigos mais antigos que o Destaques do Passado mostra?</p>
            <p class="mb-5">
                O artigo mais antigo recolhido pertence ao Diário de Notícias, um dos primeiros a ter uma página
                <i>web</i> em Portugal, em 1996. A data do arquivo existente é 11 de Agosto, tente encontrá-lo!
            </p>

            <p class="lead">Nasci em 1999, e queria ver os artigos para o meu dia de nascimento. Se o início do Destaques é 1996, porque é que o meu ano não aparece?</p>
            <p class="mb-5">
                Infelizmente nem todos os dias desde 1996 foram recolhidos pelo Arquivo.pt. Isto é, o batedor
                do Arquivo.pt não funciona em todos os dias do ano para todos os anos, podendo assim originar "buracos"
                para determinadas datas. Por exemplo, no dia 02/06 os arquivos mais antigos existentes datam de 2002, o
                que quer dizer que o Arquivo.pt não terá corrido no dia 02/06/2001, nem no dia 02/06/2000, idem até 1996.
                Outro exemplo: temos um arquivo para o dia 11/08/1996, mas o próximo dia 11/08 a ter sido arquivado
                foi o de 2004. Este facto é alheio ao Destaques do Passado e, salvo eventuais acrescentos à base de
                dados do Arquivo.pt, impossível de resolver.
            </p>

            <p class="lead">Encontrei uma notícia errada/que não faz sentido, ou tenho uma sugestão</p>
            <p class="mb-5">
                O Destaques do Passado recolheu as notícias com um <i>parser</i> semi-automático, pelo que algumas
                notícias poderão ter sido capturadas erradamente ou de forma parcial, ou ter mesmo algumas
                incongruências na fonte original, estas últimas alheias ao Destaques do Passado. Agradecemos o contacto
                para <a href="mailto:contacto@destaquesdopassado.pt">contacto@destaquesdopassado.pt</a> para analisar a
                situação. Também agradecemos qualquer sugestão para melhorar o sítio.
            </p>
        </div>
    </div>
</div>
</template>

<script>
import store from '@/store/index'

export default {
    async beforeRouteEnter(to, from, next) {
        window.scrollTo(0,0)
        next()
    },
    async beforeRouteLeave(to, from, next) {
        await store.dispatch('setFetch', false)
        next()
    }
}

</script>

<style scoped>
.nav-link {
    font-family: Andada, serif;
}

.nav-link.active {
    color: black;
}
</style>
