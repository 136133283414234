<template>
<article :class="classes" class="card article-main mb-3">
    <div v-if="article['pretitle']" class="card-header bg-white border-0 pb-0">
        <h5 aria-label="antetitulo">
            {{ article['pretitle'] }}
        </h5>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col">
                <div class="row no-gutters">
                    <div class="col-12">
                        <h3 class="card-title">
                            <a :href="articleUrl" target="_blank" class="clickable" :class="{'text-muted no-underline': !articleUrl}" aria-label="titulo" ref="article_title">
                                {{ article['title'] }}
                            </a>
                        </h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <h5 class="card-subtitle mb-2 text-muted" aria-label="fonte-original">{{ article['source'] }}, {{ article['year'] }}</h5>
                        <div class="card-text mb-2" aria-label="lead">
                            {{ article['snippet'] }}
                        </div>
                        <a :href="article['arquivo_source_url']" class="card-link small text-secondary" target="_blank">
                            <i class="fa fa-file-alt"></i> Fonte
                        </a>
                    </div>
                    <!-- Img is below title when there's a snippet -->
                    <div v-if="article['img_url'] && article['snippet']" class="col-5 col-md-4">
                        <div class="article-img" :style="articleImgBackground" />
                    </div>
                </div>
            </div>
            <!-- Img is right of everything when there's no snippet -->
            <div v-if="article['img_url'] && !article['snippet']" class="col-5 col-md-4">
                <div class="article-img" :style="articleImgBackground" />
            </div>
        </div>
    </div>
</article>
</template>

<script>
import article from '@/mixins/article'
import '@/assets/article_animations.css'

export default {
    name: 'CategoryArticle',
    mixins: [
        article
    ]
}
</script>

<style scoped>
.card {
    z-index: 1000;
    border: 0;
}

.card-body {
    padding-top: 0;
    padding-left: 0;
}

.card-header {
    padding-left: 0;
}

.article-img {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    min-height: 6rem;
}
</style>
