<template>
<section>
    <h4 class="small-caps">Primeira Hora</h4>
    <div class="list-group">
        <SmallArticle v-for="(article) in articleList" :key="article.identifier"
                      :identifier="`${article.identifier}`"
                      :article="article.content"
                      :mountedCallback="mountConfirmation"
        />
    </div>
</section>
</template>

<script>
import section from '@/mixins/section'
import SmallArticle from '@/components/articles/SmallArticle'

export default {
    name: 'CategorySection',
    components: {
        SmallArticle
    },
    mixins: [
        section
    ]
}
</script>

<style scoped>

</style>
