import {randomString, shuffle} from '@/util'

const getYearsAsc = (yearMap) => {
    const sorted = []
    for (const year in yearMap)
        sorted.push([year, yearMap[year]])

    sorted.sort((a, b) => {
        return a[1] - b[1]
    })

    return sorted.map(e => parseInt(e[0]))
}

const categorySizes = (categories, categoryMetadata) => {
    let largeCategories = []
    let mediumCategories = []
    let smallCategories = []

    for (let i = 0; i < categories.length; i++) {
        const category = categories[i]

        if (category === 'Genérico' || category === 'Miscelânea' || categoryMetadata[category]['total'] < 3)
            continue

        if (largeCategories.length < 3 && categoryMetadata[category]['large'] >= 2 && categoryMetadata[category]['total'] >= 6)
            largeCategories.push(category)
        else if (mediumCategories.length < 4 && categoryMetadata[category]['large'] >= 1 && categoryMetadata[category]['total'] >= 3)
            mediumCategories.push(category)
        else
            smallCategories.push(category)
    }

    return {largeCategories, mediumCategories, smallCategories}
}

const categorySizesLocal = (categories, categoryMetadata, articles, year) => {
    // determine category sizes locally, instead of relying on the pre-computed ones,
    // useful when filtering articles (e.g. by year), which makes the pre-computation invalid
    let largeCategories = []
    let mediumCategories = []
    let smallCategories = []

    for (let i = 0; i < categories.length; i++) {
        const category = categories[i]

        if (category === 'Genérico' || category === 'Miscelânea' || categoryMetadata[category]['total'] < 3)
            continue

        const categoryArticles = articles.filter(a => a['category'] === category && (!year || a['year'] === year))
        const large = categoryArticles.filter(a => a['img_url'] || a['snippet']).length

        // re-exclude small categories, now with locally computed total
        if (categoryArticles.length < 3)
            continue

        if (largeCategories.length < 3 && large >= 2 && categoryArticles.length >= 6)
            largeCategories.push(category)
        else if (mediumCategories.length < 4 && large >= 1 && categoryArticles.length >= 3)
            mediumCategories.push(category)
        else
            smallCategories.push(category)
    }

    return {largeCategories, mediumCategories, smallCategories}
}

const getPopularCategories = (rng, categoryMetadata, articles, year) => {
    const categories = shuffle(rng, Object.keys(categoryMetadata))
    let {largeCategories, mediumCategories, smallCategories} = year? categorySizesLocal(categories, categoryMetadata, articles, year) : categorySizes(categories, categoryMetadata)

    largeCategories = shuffle(rng, largeCategories)
    mediumCategories = shuffle(rng, mediumCategories)
    smallCategories = shuffle(rng, smallCategories)

    // when there are less than 2 large and medium categories do not show
    if (largeCategories.length + mediumCategories.length < 2)
        return {'large': [], 'medium': [], 'small': []}

    // at most 4 small categories
    smallCategories = smallCategories.filter((e, i) => i < 4)

    // make small categories an even number (to fit side-by-side if needed)
    if (smallCategories.length % 2 !== 0)
        smallCategories = smallCategories.filter((e, i) => i < smallCategories.length - 1)

    return {'large': largeCategories, 'medium': mediumCategories, 'small': smallCategories}
}

const selectNews = (rng, articles, yearMap, needsText, needsImage, needsPretitle, category, searchTerm, year) => {
    if (searchTerm) {
        const exact = searchTerm[0] === '!' || searchTerm[0] === '@'

        let keys
        if (searchTerm[0] === '@')
            keys = ['source']
        else
            keys = ['title', 'snippet', 'pretitle']

        // remove first char if special
        if (exact)
            searchTerm = searchTerm.substring(1)

        const options = {
            includeScore: true,
            keys: keys,
            threshold: exact? 0.0 : 0.4,
            ignoreLocation: true
        }

        const fuse = new Fuse(articles, options)
        const result = fuse.search(searchTerm)

        const potentialArticles = result.map(e => e.item)

        if (potentialArticles.length === 0)
            return null

        const ret = potentialArticles[0]
        articles.splice(articles.indexOf(ret), 1)
        return ret
    } else {
        let potentialArticles = articles.filter(article =>
            (!needsText || !!article['snippet']) &&
            (!needsImage || !!article['img_url']) &&
            (!category || article['category'] === category) &&
            (!year || article['year'] === year)
        )

        const withPretitle = potentialArticles.filter(article => !needsPretitle || !!article['pretitle'])
        if (withPretitle.length > 0 && rng() > 0.8) // since there are few articles with pretitles we don't want to bias the results with the same articles every time, thus only 20%
            potentialArticles = withPretitle

        const withWorkingUrl = potentialArticles.filter(article => article['has_article_url'])
        if (withWorkingUrl.length > 0 && rng() > 0.7)
            potentialArticles = withWorkingUrl

        if (potentialArticles.length === 0)
            return null

        // get article from the least popular possible year
        const years = getYearsAsc(yearMap)
        for (let i = 0; i < years.length; i++) {
        // try to find a match in the current year
            const matches = potentialArticles.filter(a => a.year === years[i])
            if (matches.length > 0) {
                const ret = matches[0]

                // match found, tally year used and remove from list of unchosen ones
                yearMap[ret['year']] += 1
                articles.splice(articles.indexOf(ret), 1)
                return ret
            }
        }

        throw 'No suitable year found!'
    }
}

export const selectArticles = (rng, articles, metadata, searchTerm, year) => {
    const MAIN_ARTICLES_COUNT = searchTerm? 15 : 6
    const LATEST_ARTICLES_COUNT = searchTerm? 20 : 9
    const OTHER_ARTICLES_COUNT = 30
    const keepSelectionOrder = !!searchTerm

    //console.time('selectArticles')

    let mainArticles = []
    let latestArticles = []
    let categoryArticles = {}
    let otherArticles = []

    articles = shuffle(rng, articles)

    // track which year is more popular, so we can distribute news more evenly
    const yearMap = {}
    metadata['years'].forEach((y) => yearMap[y] = 0)

    const selectAndInsertArticle = (dest, needsText, needsImage, needsPretitle, category, size) => {
        const article = selectNews(rng, articles, yearMap, needsText, needsImage, needsPretitle, category, searchTerm, year)
        if (article) {
            dest.push({
                content: article,
                size: size,
                identifier: randomString(rng, 10),
                mounted: false,
                showTimeout: null,
                hideTimeout: null
            })
        }
    }

    // keep articles separated so we can shuffle them without losing their hierarchical order
    let mainImages = []
    let mainRest = []

    // choose main articles with image
    for (let i = 0; i < MAIN_ARTICLES_COUNT; i++) {
        const wantImage = i < 2 || rng() > 0.5
        selectAndInsertArticle(mainImages, true, wantImage, true,'Genérico')
    }

    // fill main articles, images not needed
    let needToGet = MAIN_ARTICLES_COUNT - (mainImages.length + mainRest.length) // the value needs to be "set in stone" so that it's not re-evaluated in the for
    for (let i = 0; i < needToGet; i++)                                         // also don't change the total value, because otherwise we'de subtracting everytime, even if no articles were added, was a bug for a while
        selectAndInsertArticle(mainRest, true, false, true, 'Genérico')

    // fill main articles with small articles as last resort
    needToGet = MAIN_ARTICLES_COUNT - (mainImages.length + mainRest.length)
    for (let i = 0; i < needToGet; i++)
        selectAndInsertArticle(mainRest, false, false, false, 'Genérico')

    // add to main array
    mainImages.forEach(a => mainArticles.push(a))
    mainRest.forEach(a => mainArticles.push(a))

    // shuffle stuff around so we don't get the same sequence of years everytime
    if (!keepSelectionOrder)
        mainArticles = shuffle(rng, mainArticles)

    // choose latest news articles
    for (let i = 0; i < LATEST_ARTICLES_COUNT; i++)
        selectAndInsertArticle(latestArticles, false, false, false,'Genérico')

    if (!keepSelectionOrder)
        latestArticles = shuffle(rng, latestArticles)

    if (searchTerm) {
        //console.timeEnd('selectArticles')
        // no categories or others
        return {
            main: mainArticles,
            latest: latestArticles,
            category: {},
            other: []
        }
    }

    // get some random popular categories
    const chosenCategories = getPopularCategories(rng, metadata['categories'], articles, year)

    const selectCategoryArticles = (category, largeArticlesNeeded, smallArticlesNeeded) => {
        let features = []
        let others = []

        // try to get one with image and snippet
        for (let j = 0; j < largeArticlesNeeded; j++)
            selectAndInsertArticle(features, true, true, false, category, 'large')

        // try to get one with image
        let needToGet = largeArticlesNeeded - features.length
        for (let j = 0; j < needToGet; j++)
            selectAndInsertArticle(features, false, true, false, category, 'large')

        // try to get one with snippet
        needToGet = largeArticlesNeeded - features.length
        for (let j = 0; j < needToGet; j++)
            selectAndInsertArticle(features, true, false, false, category, 'large')

        // get small articles
        for (let j = 0; j < smallArticlesNeeded; j++)
            selectAndInsertArticle(others, false, false, false, category, 'small')

        if (!keepSelectionOrder) {
            features = shuffle(rng, features)
            others = shuffle(rng, others)
        }

        const chosenCategoryArticles = []
        features.forEach(a => chosenCategoryArticles.push(a))
        others.forEach(a => chosenCategoryArticles.push(a))
        return chosenCategoryArticles
    }

    chosenCategories['large'].forEach((category) => {
        categoryArticles[category] = selectCategoryArticles(category, 2, 5)
    })

    chosenCategories['medium'].forEach((category) => {
        categoryArticles[category] = selectCategoryArticles(category,1, 3)
    })

    chosenCategories['small'].forEach((category) => {
        categoryArticles[category] = selectCategoryArticles(category,0, 3)
    })

    // choose some other news for the bottom of the page
    let othersText = []
    let othersNoText = []
    for (let i = 0; i < Math.round(OTHER_ARTICLES_COUNT/2); i++)
        selectAndInsertArticle(othersText, true, false, false,null)

    for (let i = 0; i < Math.round(OTHER_ARTICLES_COUNT/2); i++)
        selectAndInsertArticle(othersNoText, false, false, false,null)

    if (!keepSelectionOrder) {
        othersText = shuffle(rng, othersText)
        othersNoText = shuffle(rng, othersNoText)
    }

    othersText.forEach(a => otherArticles.push(a))
    othersNoText.forEach(a => otherArticles.push(a))

    //console.timeEnd('selectArticles')
    return {
        main: mainArticles,
        latest: latestArticles,
        category: categoryArticles,
        other: otherArticles
    }
}
