<template>
<section :class="features.length > 0? 'col-12' : 'col-12 col-md-6'">
    <h2 class="small-caps mb-3">{{ category }}</h2>
    <div class="row">
        <div class="col-12 col-md-7" v-if="features.length > 0">
            <CategoryArticle v-for="(article) in features" :key="article.identifier"
                             :identifier="`${article.identifier}`"
                             :article="article.content"
                             :mountedCallback="mountConfirmation"
            />
        </div>
        <div :class="classesRight">
            <div class="list-group pl-4 pl-md-0">
                <SmallArticle v-for="(article) in others" :key="article.identifier"
                              :identifier="`${article.identifier}`"
                              :article="article.content"
                              :mountedCallback="mountConfirmation"
                              :reservePretitleSpace="features.length === 0"
                /> <!-- reserve space so that side-by-side news look like a grid -->
            </div>
        </div>
    </div>
</section>
</template>

<script>
import SmallArticle from '@/components/articles/SmallArticle'
import CategoryArticle from '@/components/articles/CategoryArticle'
import section from '@/mixins/section'

export default {
    name: 'CategorySection',
    components: {
        CategoryArticle,
        SmallArticle
    },
    mixins: [
        section
    ],
    props: {
        category: {
            type: String
        }
    },
    computed: {
        features() {
            return this.articleList.filter(a=> a.size === 'large')
        },
        others() {
            return this.articleList.filter(a=> a.size === 'small')
        },
        classesRight() {
            return this.features.length > 0 ? 'col-12 col-md-5' : 'col-12'
        }
    }
}
</script>

<style scoped>
</style>
