<template>
<header class="enter-above-animation fixed-top" :class="headerStyle">
    <div class="container">
        <!-- Logo, title and hamburguer-->
        <div class="row d-flex align-items-center justify-content-center">
            <div class="col-2 col-sm-3 px-1 px-sm-auto align-middle text-center">
                <router-link :to="{'name': 'home'}" :class="{'not-clickable': isAtHome}">
                    <img src="@/assets/logo.png" alt="Logótipo" id="logo" class="img-fluid" :class="imgSize" />
                </router-link>
            </div>

            <div class="col px-1 px-sm-auto align-middle text-center" :class="titleClass">
                <div id="title" :class="!isAtTop? 'white-cursor' : 'black-cursor'" />
            </div>

            <div v-if="isAtHome" class="col-auto px-1 px-sm-auto" id="hamburguer-menu" :class="hamburguerClass">
                <i class="fas fa-bars clickable" @click="toggle"></i>
            </div>
        </div>

        <!-- Top bar for articles page-->
        <div v-if="isAtHome" id="edition" class="row align-items-center small-caps mt-2 pt-2" :class="editionRowClass">
            <div class="col-auto mx-auto col-xl-8 d-flex flex-wrap align-items-center">
                <div class="d-none d-md-inline pr-2">Edição de</div>
                <DaySelector id="day-select" class="d-inline" :allowSearch="!isMobile" />
                <div class="d-inline px-2">de</div>
                <MonthSelector id="month-select" class="d-inline" :allowSearch="!isMobile" />
                <div v-if="isLoaded" class="d-inline pl-2 loaded-animate">de </div>
                <YearSelector v-if="isLoaded" class="d-inline pl-0 pl-sm-2 pt-2 pt-sm-0 loaded-animate" :key="$store.state.metadata.years.toString()" />
            </div>

            <div class="col-12 col-xl-4 mt-4 mt-xl-0">
                <div class="row no-gutters align-items-center">
                    <SearchBar class="col" :isDark="!isAtTop" />
                    <RefreshButton class="col-auto pl-3 pr-2" :isDark="!isAtTop" />
                    <AboutButton class="col-auto pr-2" :isDark="!isAtTop" />
                    <ShareButton class="col-auto" :isDark="!isAtTop" />
                </div>
            </div>
        </div>
    </div>
</header>
</template>

<script>
import { debounce } from 'lodash'

import {mapGetters} from 'vuex'
import RefreshButton from '@/components/header/RefreshButton'
import SearchBar from '@/components/header/SearchBar'
import AboutButton from '@/components/header/AboutButton'
import DaySelector from '@/components/header/DaySelector'
import MonthSelector from '@/components/header/MonthSelector'
import YearSelector from '@/components/header/YearSelector'
import ShareButton from '@/components/header/ShareButton'

import '@/assets/selector.css'

export default {
    components: {
        YearSelector,
        ShareButton,
        DaySelector,
        MonthSelector,
        SearchBar,
        RefreshButton,
        AboutButton
    },
    computed: {
        ...mapGetters([
            'isLoaded'
        ]),
        isAtHome() {
            return this.$route.name === 'home'
        },
        titleClass() {
            return this.isAtTop? 'display-4' : 'h2'
        },
        editionRowClass () {
            return this.isAtTop? 'h3' : 'h5'
        },
        imgSize () {
            return this.isAtTop? 'large' : 'small'
        },
        hamburguerClass () {
            return this.isAtTop? 'd-none': 'h1'
        },
        headerStyle () {
            return this.isAtTop? 'bg-white pt-2' : 'bg-title pt-1'
        }
    },
    data() {
        return {
            isMobile: window.matchMedia("only screen and (max-width: 760px)").matches,
            isAtTop: document.documentElement.scrollTop < 70,
            manualShow: false,
            isShown: true
        }
    },
    methods: {
        show() {
            $('#edition').slideDown({
                duration: 200,
                complete: this.shown,
                easing: 'linear'
            })
        },
        hide() {
            $('#edition').slideUp({
                duration: 200,
                complete: this.hidden,
                easing: 'linear'
            })
        },
        shown() {
            this.isShown = true
        },
        hidden() {
            this.isShown = false
        },
        toggle() {
            this.manualShow = !this.manualShow

            if (this.manualShow)
                this.show()
            else
                this.hide()
        },
        scrollFunc() {
            // relies on the fact that the scroll function triggers this, otherwise we'd
            // need to update this manually when that happens
            this.isAtTop = document.documentElement.scrollTop < 70

            if (this.isAtTop || this.manualShow) {
                if (!this.isShown)
                    this.show()
            } else {
                if (this.isShown)
                    this.hide()
            }
        }
    },
    mounted() {
        try {
            new TypeIt('#title', {
                strings: 'Destaques do Passado',
                speed: 50,
                waitUntilVisible: true,
                afterComplete: (step, instance) => {
                    instance.destroy()
                }
            }).go()
        } catch (err) {
            // fallback
            $('#title').text('Destaques do Passado')
        }

        // shrink on scroll
        window.addEventListener('scroll', debounce(this.scrollFunc, 100))
    }
}
</script>

<style scoped>
header {
    z-index: 2000;
}

@font-face {
    font-family: TitleFont;
    src: url(../assets/fonts/AtariClassic.ttf);
}

#title {
    font-family: TitleFont, monospace;
}

header, #logo, #title, #hamburguer-menu, #edition {
    transition: all 0.4s ease;
}

#day-select {
    width: auto;
    min-width: 3.5em;
}

#month-select {
    width: auto;
    min-width: 6.5em;
}

@media (max-width: 1200px) {
    #logo.large {
        max-height: calc(1.575rem + 6.3vw);
    }

    #logo.small {
        max-height: calc(1.375rem + 3.8vw);
    }
}

@media (min-width: 1201px) {
    #logo.small {
        max-height: calc(1.3rem + 2vw);
    }
}

/* Appear animation */
@keyframes enterFromAboveTopBar {
    0% {
        transform: translateY(-2rem);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes leaveToAboveTopBar {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-2rem);
        opacity: 0;
    }
}

.enter-above-animation {
    animation: 1s ease-out 0s 1 enterFromAboveTopBar;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.loaded-animate {
    animation: 0.5s ease-out 0s 1 fadeIn;
}
</style>
