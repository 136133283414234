export default {
    props: {
        article: {
            type: Object,
            required: true
        },
        identifier: {
            type: String,
            required: true
        },
        mountedCallback: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            enterAnimation: 'enter-animation',
            leaveAnimation: 'leave-animation',
            enter: false,
            leave: false,
            animateEntry: true,
            animateExit: true,
            popoverTimeout: null
        }
    },
    computed: {
        classes() {
            if (this.leave)
                return this.animateExit? this.leaveAnimation : 'hide'

            if (this.enter)
                return this.animateEntry? this.enterAnimation : ''

            return 'hide'
        },
        articleUrl () {
            return this.article['has_article_url']? this.article['article_url'] : null
        },
        articleImgBackground() {
            return `background-image: url('${this.article['img_url']}');`
        }
    },
    methods: {
        showMyself(animate) {
            this.animateEntry = animate
            this.enter = true
            this.leave = false
        },
        hideMyself(animate) {
            this.animateExit = animate
            this.enter = false
            this.leave = true
        }
    },
    created() {
        this.$root.$on(`show-element-${this.identifier}`, (animate) => this.showMyself(animate))
        this.$root.$on(`hide-element-${this.identifier}`, (animate) => this.hideMyself(animate))
    },
    mounted() {
        if (!this.article['has_article_url'])
            $(this.$refs.article_title).popover({content: 'Artigo original não disponível no arquivo.pt', trigger: 'click', placement: 'right'})

        // hide title popover after 2 seconds
        $(this.$refs.article_title).on('shown.bs.popover', () => {
            this.popoverTimeout = setTimeout(() => {
                $(this.$refs.article_title).popover('hide')
            }, 2000)
        })

        // signal to my parent I'm mounted, when all siblings are mounted parent
        // shall instruct us to sequentially show up
        this.mountedCallback(this.identifier)
    },
    beforeDestroy() {
        if (this.popoverTimeout)
            clearTimeout(this.popoverTimeout)

        // hide popover so that it doesn't linger
        $(this.$refs.article_title).popover('hide')
    }
}
