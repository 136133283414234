<template>
<div ref="button" :class="isDark? 'text-white' : 'text-muted'" @click="refreshArticles">
    <i class="fa fa-sync" :class="isTransitioning? 'rotating' : 'clickable'"></i>
</div>
</template>

<script>

import {mapGetters} from 'vuex'
import store from '@/store'
import button from '@/mixins/button'

export default {
    mixins: [
        button
    ],
    computed: {
        ...mapGetters([
            'isTransitioning'
        ]),
    },
    data() {
        return {
            tooltip: 'Renovar a página'
        }
    },
    methods: {
        async refreshArticles () {
            if (this.disabled)
                return

            if (!this.isTransitioning)
                await store.dispatch('populateArticles')
        }
    }
}
</script>

<style scoped>
@keyframes spin {
    from {
        transform: rotate(0deg);
    } to {
          transform: rotate(360deg);
    }
}

.rotating {
    animation: spin 4s linear infinite;
}
</style>
