<template>
<div class="modal fade" ref="modalcopy" id="share-modal" tabindex="-1" role="dialog" aria-label="Partilhar esta página e pesquisa" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content col-12">
            <div class="modal-header">
                <h5 class="modal-title">Partilhar</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Fechar"><span aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col">

                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="date-check" v-model="wantsDate">
                            <label class="custom-control-label" for="date-check">
                                Incluir dia/mês? <small class="text-muted">{{ humanDate }}</small>
                            </label>
                        </div>

                        <div class="custom-control custom-checkbox" v-if="searchTerm">
                            <input type="checkbox" class="custom-control-input" id="search-check" v-model="wantsSearch">
                            <label class="custom-control-label" for="search-check">
                                Incluir pesquisa? <small class="text-muted">{{ searchTerm }}</small>
                            </label>
                        </div>

                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="version-check" v-model="wantsVersion" :disabled="!wantsDate">
                            <label class="custom-control-label" for="version-check">
                                Mesma versão? <small class="text-muted">Mesmos artigos e ordenação <template v-if="!wantsDate">(requer mesma data)</template></small>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="input-group bg-white text-muted">
                        <input class="form-control col" disabled type="url" v-model="url" aria-label="Ligação a partilhar" />

                        <div class="input-group-append clickable" ref="copybtn" :data-clipboard-text="url">
                            <div class="input-group-text text-muted">
                                <i class="far fa-clone"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import store from '@/store/index'
import {getHumanDate} from '@/util'

export default {
    computed: {
        humanDate() {
            return getHumanDate(store.state.selectedDay, store.state.selectedMonth)
        },
        searchTerm() {
            return store.state.searchTerm
        },
        url() {
            let str = 'https://destaquesdopassado.pt/'

            if (this.wantsDate || this.wantsSearch)
                str += '?'

            if (this.wantsDate)
                str += `dia=${store.state.selectedDay}&mes=${store.state.selectedMonth+1}`

            if (this.wantsSearch) {
                if (this.wantsDate)
                    str += '&'
                str += `pesquisa=${this.searchTerm}`
            }

            if (this.wantsVersion && this.wantsDate) {  // only works when wants date is true too
                if (this.wantsDate || this.wantsSearch)
                    str += '&'
                str += `versao=${store.state.seed}`
            }

            return str
        }
    },
    data() {
        return {
            wantsDate: true,
            wantsSearch: false,
            wantsVersion: false,
            hideTimeout: null,
            clipboard: null
        }
    },
    mounted() {
        $(this.$refs.copybtn).tooltip({title: 'Copiado!', trigger: 'manual', placement: 'top'})

        this.clipboard = new ClipboardJS(this.$refs.copybtn, {
            container: this.$refs.modalcopy
        })

        this.clipboard.on('success', () => {
            $(this.$refs.copybtn).tooltip('show')

            this.hideTimeout = setTimeout(() => {
                $(this.$refs.copybtn).tooltip('hide')
                this.hideTimeout = null
            }, 1500)
        })
    },
    beforeDestroy() {
        $(this.$refs.copybtn).tooltip('hide')

        // just to guarantee the modal and its styles don't linger
        $('#share-modal').modal('hide')

        if (this.hideTimeout) {
            clearTimeout(this.hideTimeout)
            this.hideTimeout = null
        }
    }
}
</script>

<style scoped>
.modal {
    z-index: 3000;
}

.input-group .form-control {
    background-color: #dfe5fb !important;
    border: 0;
    border-radius: 4px;
}

.input-group-append {
    border-left: 1px solid;
}
</style>
