<template>
<div class="container">
    <div class="row mb-4 align-items-center">
        <div class="col-12">
            <ul class="nav h3">
                <li class="nav-item">
                    <router-link :to="{'name': 'home'}" class="nav-link pl-0">Início</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{'name': 'about'}" class="nav-link pl-0">Sobre</router-link>
                </li>
                <li class="nav-item">
                    <a class="nav-link active pl-0">API</a>
                </li>
                <li class="nav-item">
                    <router-link :to="{'name': 'stats'}" class="nav-link pl-0">Estatísticas</router-link>
                </li>
            </ul>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-12">
            <h2>API</h2>
            <p>
                A API do Destaques do Passado possui apenas um endpoint: <code>https://api.destaquesdopassado.pt/&lt;mês&gt;&lt;dia&gt;.json</code>
            </p>
            <p>
                (Exemplo para o dia de Natal: <code>https://api.destaquesdopassado.pt/1225.json</code>)
            </p>

            <p>É composta por 366 ficheiros, cada um contendo as notícias para esse dia do ano. Os ficheiros estão em
            formato JSON, sendo compostos por um <i>array</i> de <i>objects</i>, cada um destes representando uma
            notícia.</p>

            <p>Exemplo:</p>

            <p>
            <pre><code class="json hljs">{
    article_url: <span class="hljs-string">"https://arquivo.pt/wayback/20111225160405/http://publico.pt/Sociedade/jose-deixa-uma-flor-no-metro-todos-os-dias-em-nome-do-amor-1526419"</span>,
    arquivo_source_url: <span class="hljs-string">"https://arquivo.pt/wayback/20111225160405/http://publico.pt/"</span>,
    title: <span class="hljs-string">"José deixa uma flor no metro todos os dias em nome do amor"</span>,
    source: <span class="hljs-string">"Público"</span>,
    year: <span class="hljs-number">2011</span>,
    importance: <span class="hljs-number">4</span>,
    category: <span class="hljs-string">"Genérico"</span>,
    pretitle: <span class="hljs-literal">null</span>,
    snippet: <span class="hljs-string">"Há mais de dez meses que, todos os dias, uma flor viaja clandestinamente no metro de Lisboa. José propôs-se a espalhar o amor pela capital durante um ano, com flores e poesia."</span>,
    img_url: <span class="hljs-string">"https://arquivo.pt/noFrame/replay/20111225160405im_/http://imagens.publico.pt/imagens.aspx/1436729?tp=UH&amp;db=DESTAQUES&amp;w=360&amp;t=1324828809,65431"</span>,
    has_article_url: <span class="hljs-number">1</span>
}</code></pre>
            </p>

            <p>O significado de cada campo é o seguinte:</p>

            <dl class="row">
                <dt class="col-sm-4">Campo</dt>
                <dd class="col-sm-8">Significado</dd>

                <dt class="col-sm-4"><code>article_url</code></dt>
                <dd class="col-sm-8">Ligação para o corpo do artigo (conforme arquivado pelo arquivo.pt), tal como constava do sítio original (isto é, a ligação que se seguia ao carregar no título da notícia)</dd>

                <dt class="col-sm-4"><code>arquivo_source_url</code></dt>
                <dd class="col-sm-8">Ligação para a fonte de onde o Destaques do Passado recolheu a notícia em causa, bem como todos os metadados associados (por exemplo, imagens)</dd>

                <dt class="col-sm-4"><code>title</code></dt>
                <dd class="col-sm-8">Título da notícia</dd>

                <dt class="col-sm-4"><code>pretitle</code></dt>
                <dd class="col-sm-8">Antetítulo da notícia, se existente</dd>

                <dt class="col-sm-4"><code>snippet</code></dt>
                <dd class="col-sm-8">Resumo que acompanha a notícia, usualmente o <i>lead</i>, se existente</dd>

                <dt class="col-sm-4"><code>source</code></dt>
                <dd class="col-sm-8">Órgão de comunicação social de onde a notícia foi extraída</dd>

                <dt class="col-sm-4"><code>year</code></dt>
                <dd class="col-sm-8">Ano da notícia (o dia e mês podem ser inferidos pelo nome do ficheiro)</dd>

                <dt class="col-sm-4"><code>category</code></dt>
                <dd class="col-sm-8">
                    <p>Categoria da notícia, quando possível de ser inferida automaticamente, ou "Genérico", caso contrário, podendo ser uma de:</p>
                    <p><code>Genérico, Desporto, Portugal, Mundo, Economia, Entretenimento, Ciência, Saúde, Política, Cultura, Educação, Tecnologia, Sociedade, Local, Ambiente, Opinião, Insólito, Miscelânea</code></p>
                </dd>

                <dt class="col-sm-4"><code>importance</code></dt>
                <dd class="col-sm-8">
                    <p>Valor numérico da proeminência do artigo na página, relativo aos restantes. Valores possíveis são:</p>
                    <p><code><b>5</b>: Destaque; <b>4</b>: Grande; <b>3</b>: Pequeno; <b>2</b>: "Última Hora"; <b>1</b>: Relacionado (com um artigo principal); <b>0</b>: Desconhecido</code></p>
                </dd>

                <dt class="col-sm-4"><code>img_url</code></dt>
                <dd class="col-sm-8">Ligação para um ficheiro de imagem associado à notícia, se existente</dd>

                <dt class="col-sm-4"><code>has_article_url</code></dt>
                <dd class="col-sm-8">Booleano que indica se o corpo da notícia foi arquivado pelo Arquivo.pt (isto é, se um pedido HTTP devolve 200)</dd>
            </dl>

            <p>Adicionalmente, cada ficheiro tem associada uma chave de metadados:</p>

            <p>
            <pre><code class="json hljs"><span class="hljs-string">"metadata"</span>: {
    <span class="hljs-attr">"total"</span>: <span class="hljs-number">475</span>,
    <span class="hljs-attr">"snippets"</span>: <span class="hljs-number">309</span>,
    <span class="hljs-attr">"imgs"</span>: <span class="hljs-number">58</span>,
    <span class="hljs-attr">"categories"</span>: {
        <span class="hljs-attr">"Genérico"</span>: {<span class="hljs-attr">"total"</span>: <span class="hljs-number">170</span>, <span class="hljs-attr">"large"</span>: <span class="hljs-number">119</span>, <span class="hljs-attr">"small"</span>: <span class="hljs-number">51</span>},
        <span class="hljs-attr">"Desporto"</span>: {<span class="hljs-attr">"total"</span>: <span class="hljs-number">47</span>, <span class="hljs-attr">"large"</span>: <span class="hljs-number">28</span>, <span class="hljs-attr">"small"</span>: <span class="hljs-number">19</span>},
        <span class="hljs-attr">"Portugal"</span>: {<span class="hljs-attr">"total"</span>: <span class="hljs-number">21</span>, <span class="hljs-attr">"large"</span>: <span class="hljs-number">13</span>, <span class="hljs-attr">"small"</span>: <span class="hljs-number">8</span>}
    },
    <span class="hljs-attr">"years"</span>: [<span class="hljs-number">2005</span>, <span class="hljs-number">2006</span>, <span class="hljs-number">2007</span>, <span class="hljs-number">2010</span>, <span class="hljs-number">2011</span>, <span class="hljs-number">2014</span>, <span class="hljs-number">2015</span>]
}</code></pre>
            </p>

            <dl class="row">
                <dt class="col-sm-4">Campo</dt>
                <dd class="col-sm-8">Significado</dd>

                <dt class="col-sm-4"><code>total</code></dt>
                <dd class="col-sm-8">Total de artigos para o dia</dd>

                <dt class="col-sm-4"><code>snippets</code></dt>
                <dd class="col-sm-8">Total de artigos contendo <i>lead</i> ou um resumo da notícia</dd>

                <dt class="col-sm-4"><code>imgs</code></dt>
                <dd class="col-sm-8">Total de artigos com imagem associada</dd>

                <dt class="col-sm-4"><code>categories</code></dt>
                <dd class="col-sm-8">
                    <p>
                        <i>Object</i> JSON cujas chaves correspondem às categorias existentes. O valor contém o total de artigos para cada categoria,
                        bem como quantos desses artigos são <i>large</i> (contêm imagem e/ou <i>lead</i>) ou <i>small</i> (apenas título e antetítulo, se existente).
                    </p>
                </dd>

                <dt class="col-sm-4"><code>years</code></dt>
                <dd class="col-sm-8">Lista de anos representados pelos artigos do ficheiro</dd>
            </dl>

            <p>Poderá obter mais detalhes sobre a geração do repositório no <a href="https://github.com/sgtpepperpt/destaques-do-passado" target="_blank">GitHub</a>.</p>
        </div>
    </div>
</div>
</template>

<script>
import store from '@/store/index'

import '@/assets/highlightjs-github.css'

export default {
    async beforeRouteEnter(to, from, next) {
        window.scrollTo(0,0)
        next()
    },
    async beforeRouteLeave(to, from, next) {
        await store.dispatch('setFetch', false)
        next()
    }
}

</script>

<style scoped>
.nav-link {
    font-family: Andada, serif;
}

.nav-link.active {
    color: black;
}
</style>
