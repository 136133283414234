<template>
    <div class="container">
        <main class="row mb-4" v-if="isLoaded">
            <div class="col-12 py-5 text-center" v-if="!$store.state.isTransitioning && selectedArticles['main'].length === 0 && selectedArticles['latest'].length === 0 && selectedArticles['other'].length === 0 && popularCategories.length === 0">
                <h2>Nenhum artigo encontrado!</h2>
            </div>
            <MainSection class="col"
                         sectionName="main"
                         :showDelay="700"
                         :articleList="selectedArticles['main']"
            />
            <LatestSection class="col-12 col-lg-4 mt-5 mt-lg-0"
                           v-if="selectedArticles['latest'].length > 0"
                           sectionName="latest"
                           :showDelay="100"
                           :articleList="selectedArticles['latest']"
            />
        </main>

        <div class="row my-4" v-if="isLoaded">
            <CategorySection v-for="(category) in popularCategories" :key="`${category}${randomString(10)}`"
                             class="mb-3"
                             :sectionName="`category.${category}`"
                             :showDelay="500"
                             :category="category"
                             :articleList="selectedArticles['category'][category]"
                             :waitForViewport="true"
            />
        </div>

        <div class="row my-4" v-if="isLoaded && selectedArticles['other'].length > 0">
            <OtherSection class="col-12"
                          sectionName="other"
                          :showDelay="250"
                          :articleList="selectedArticles['other']"
                          :waitForViewport="true"
            />
        </div>
    </div>
</template>

<script>
import {MONTH_DURATIONS, randomString} from '@/util'

import router from '@/router/index'
import store from '@/store/index'
import {mapGetters} from 'vuex'

import MainSection from '@/components/sections/MainSection'
import LatestSection from '@/components/sections/LatestSection'
import CategorySection from '@/components/sections/CategorySection'
import OtherSection from '@/components/sections/OtherSection'

export default {
    name: 'Home',
    components: {
        MainSection,
        LatestSection,
        CategorySection,
        OtherSection
    },
    computed: {
        ...mapGetters([
            'selectedArticles',
            'isLoaded',
            'popularCategories'
        ])
    },
    methods: {
        randomString(length) {
            return randomString(store.state.rng, length)
        },
    },
    async created() {
        // read query string for date and/or search term
        const dayParam = this.$route.query['dia']
        const monthParam = this.$route.query['mes']
        const searchParam = this.$route.query['pesquisa']

        // clean query params
        try {
            if (dayParam || monthParam || searchParam)
                await router.replace('/')
        } catch (e) {
            // just in case this fails, don't let the whole page fail...
        }

        if (dayParam && monthParam) {
            const day = parseInt(dayParam)
            const month = parseInt(monthParam) - 1

            // invalid query string date is ignored
            if (month >= 0 && month <= 12 && day >=1 && day <= MONTH_DURATIONS[month])
                await store.dispatch('selectDate', {day, month})
        }

        if (searchParam)
            await store.dispatch('setSearchTerm', searchParam)

        await store.dispatch('populateArticles')
    }
}
</script>

<style scoped>
</style>
