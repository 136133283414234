<template>
<section class="pl-0">
    <h4 class="d-none d-lg-block invisible" style="color: white">.</h4>
    <MainArticle v-for="(article) in articleList" :key="article.identifier"
                 :identifier="`${article.identifier}`"
                 :article="article.content"
                 :mountedCallback="mountConfirmation"
    />
</section>
</template>

<script>
import section from '@/mixins/section'
import MainArticle from '@/components/articles/MainArticle'

export default {
    name: 'CategorySection',
    components: {
        MainArticle
    },
    mixins: [
        section
    ]
}
</script>

<style scoped>

</style>
