export const MONTHS = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
export const MONTH_DURATIONS = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

export const getRobotDate = (day, month) => {
    return `${(month + 1).toString().padStart(2, '0')}${day.toString().padStart(2, '0')}`
}

export const getHumanDate = (day, month) => {
    return `${day} de ${MONTHS[month]}`
}

export const currentDay = () => {
    return new Date().getDate()
}

export const currentMonth = () => {
    return new Date().getMonth()
}

export const shuffle = (rng, array) => {
    let currentIndex = array.length, temporaryValue, randomIndex

    while (0 !== currentIndex) {
        randomIndex = Math.floor(rng() * currentIndex)
        currentIndex -= 1

        // And swap it with the current element.
        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
    }

    return array
}

export const randomString = (rng, length) => {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++)
        result += characters.charAt(Math.floor(rng() * charactersLength))

    return result
}
