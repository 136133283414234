import { cloneDeep } from 'lodash'
import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'

const cache = setupCache({
    maxAge: 24 * 60 * 60 * 1000
})

const client = axios.create({
    baseURL: 'https://api.destaquesdopassado.pt',
    timeout: 10000,
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    adapter: cache.adapter
})

export const requestData = async (date) => {
    const r = await client.get(`/${date}.json`)
    return cloneDeep(r.data)
}
