export default {
    props: {
        isDark: {
            type: Boolean
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tooltip: '',
            tooltipTimeout: null
        }
    },
    mounted() {
        if (this.disabled)
            return

        $(this.$refs.button).tooltip({title: this.tooltip, placement: 'top'})

        $(this.$refs.button).on('shown.bs.tooltip', () => {
            // hide tooltip after 2 seconds
            this.tooltipTimeout = setTimeout(() => {
                $(this.$refs.button).tooltip('hide')
                this.tooltipTimeout = null
            }, 2000)
        })
    },
    beforeDestroy() {
        if (this.disabled)
            return

        if (this.tooltipTimeout) {
            clearTimeout(this.tooltipTimeout)
            this.tooltipTimeout = null
        }

        $(this.$refs.button).tooltip('hide')
    }
}
