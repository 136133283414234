<template>
<div ref="button" class="text-bigger" :class="isDark? 'text-white' : 'text-muted'">
    <i class="far fa-share-square clickable" @click="openModal"></i>
</div>
</template>

<script>
import button from '@/mixins/button'

export default {
    mixins: [
        button
    ],
    data() {
        return {
            tooltip: 'Partilhar'
        }
    },
    methods: {
        openModal() {
            $('#share-modal').modal('show')
        }
    },
    beforeDestroy() {
        // just to guarantee the modal and its styles don't linger
        $('#share-modal').modal('hide')
    }
}
</script>

<style scoped>
.text-bigger {
    font-size: 110%;
}
</style>
