<template>
<section>
    <h3 class="small-caps mb-2">Mais notícias do dia...</h3>
    <div class="row">
        <div class="col-12 col-lg-6" v-for="(article) in articleList" :key="article.identifier">
            <OtherArticle
                :identifier="`${article.identifier}`"
                :article="article.content"
                :mountedCallback="mountConfirmation"
            />
        </div>
    </div>
</section>
</template>

<script>
import section from '@/mixins/section'
import OtherArticle from '@/components/articles/OtherArticle'

export default {
    name: 'CategorySection',
    components: {
        OtherArticle
    },
    mixins: [
        section
    ]
}
</script>

<style scoped>

</style>
