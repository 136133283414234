<template>
<div class="input-group" :class="backgroundClass">
    <div class="input-group-prepend" @click="$refs.searchBar.focus()">
        <div class="input-group-text text-muted" :class="backgroundClass">
            <i class="fas fa-search"></i>
        </div>
    </div>

    <input ref="searchBar" class="form-control" type="text" placeholder="Pesquisar" aria-label="Pesquisar nas notícias do dia" v-model="searchTermInput">

    <div class="input-group-append clickable" v-if="searchTerm" @click="clearSearch">
        <div class="input-group-text text-muted">
            <i class="fas fa-times"></i>
        </div>
    </div>
</div>
</template>

<script>
import store from '@/store/index'
import {mapGetters} from 'vuex'

export default {
    props: {
        isDark: {
            type: Boolean
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            searchTimeout: null
        }
    },
    computed: {
        ...mapGetters([
            'searchTerm'
        ]),
        backgroundClass() {
            return this.isDark? 'bg-title text-white' : 'bg-white text-muted'
        },
        searchTermInput: {
            async set(title) {
                if (this.disabled)
                    return

                await store.dispatch('setSearchTerm', title)
            },
            get() {
                return this.searchTerm
            }
        },
    },
    methods: {
        clearSearch() {
            this.searchTermInput = ''
        },
        async search() {
            await store.dispatch('populateArticles', {animateOnEnter: true, animateOnLeave: false})
        }
    },
    async mounted() {
        if (this.disabled)
            return

        if (this.searchTerm.length > 0)
            this.$refs.searchBar.focus()

        store.subscribe((mutation) => {
            if (mutation.type === 'SET_SEARCH_TERM') {
                _.debounce(this.search, mutation.payload? 400 : 0, {trailing: true})()
            }
        })
    }
}
</script>

<style scoped>
* {
    transition: all 0.4s ease;
    background-color: #dfe5fb !important;
    border: 0;
    border-radius: 4px;
}

.input-group-prepend .input-group-text {
    //font-size: 1.75rem;
    //padding-left: 0;
}
</style>
