<template>
<v-select class="selector day-selector" :clearable="false" :searchable="allowSearch" :options="monthDays" :value="selectedDay" @input="changeDay" v-on:search:focus="fixHighlight">
    <span slot="no-options">
        Insira um dia válido
    </span>
</v-select>
</template>

<script>
import 'vue-select/dist/vue-select.css'

import store from '@/store'
import {MONTH_DURATIONS} from '@/util'

export default {
    props: {
        allowSearch: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        selectedDay() {
            return store.state.selectedDay
        },
        selectedMonth() {
            return store.state.selectedMonth
        },
        monthDays() {
            let list = []
            for (let i = 1; i <= MONTH_DURATIONS[this.selectedMonth]; i++)
                list.push(i)
            return list
        }
    },
    methods: {
        async changeDay(day) {
            await store.dispatch('selectDate', { day: parseInt(day) })
            await store.dispatch('populateArticles')
        },
        fixHighlight () {
            this.$nextTick(() => {
                $('.day-selector .vs__dropdown-option.vs__dropdown-option--highlight').removeClass('vs__dropdown-option--highlight')
                $('.day-selector .vs__dropdown-option.vs__dropdown-option--selected').addClass('vs__dropdown-option--highlight')
            })
        }
    }
}
</script>

<style>
</style>
