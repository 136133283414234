<template>
<div class="invisible" :class="headerStyle" aria-hidden="true">
    <div class="container">
        <!-- Logo, title and hamburguer-->
        <div class="row d-flex align-items-center justify-content-center">
            <div class="col-2 col-sm-3 px-1 px-sm-auto align-middle text-center">
                <a class="not-clickable">
                    <img src="@/assets/logo.png" alt="Logótipo" id="logo-alt" class="img-fluid" :class="imgSize" />
                </a>
            </div>

            <div class="col px-1 px-sm-auto align-middle text-center" :class="titleClass">
                <div id="title-alt">Destaques do Passado</div>
            </div>

            <div v-if="isAtHome" class="col-auto px-1 px-sm-auto" :class="hamburguerClass">
                <i class="fas fa-bars clickable"></i>
            </div>
        </div>

        <!-- Top bar for articles page-->
        <div v-if="isAtHome" class="row align-items-center small-caps mt-2 pt-2" :class="editionRowClass">
            <div class="col-auto mx-auto col-xl-8 d-flex flex-wrap align-items-center">
                <div class="d-none d-md-inline pr-2">Edição de</div>
                <DaySelector id="day-select-alt" class="d-inline" />
                <div class="d-inline px-2">de</div>
                <MonthSelector id="month-select-alt" class="d-inline" />
                <div class="d-inline pl-2">de </div>
                <div id="dummy-year" class="d-inline pl-0 pl-sm-2 pt-2 pt-sm-0">2000</div>
            </div>

            <div class="col-12 col-xl-4 mt-4 mt-xl-0">
                <div class="row no-gutters align-items-center">
                    <SearchBar class="col" :disabled="true" />
                    <RefreshButton class="col-auto pl-3 pr-2" :disabled="true" />
                    <AboutButton class="col-auto pr-2" :disabled="true" />
                    <ShareButton class="col-auto" :disabled="true" />
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { debounce } from 'lodash'

import RefreshButton from '@/components/header/RefreshButton'
import SearchBar from '@/components/header/SearchBar'
import AboutButton from '@/components/header/AboutButton'
import DaySelector from '@/components/header/DaySelector'
import MonthSelector from '@/components/header/MonthSelector'
import ShareButton from '@/components/header/ShareButton'

export default {
    components: {
        ShareButton,
        DaySelector,
        MonthSelector,
        SearchBar,
        RefreshButton,
        AboutButton
    },
    computed: {
        isAtHome() {
            return this.$route.name === 'home'
        },
        titleClass() {
            return this.isAtTop? 'display-4' : 'h2'
        },
        editionRowClass () {
            return this.isAtTop? 'h3' : 'h5'
        },
        imgSize () {
            return this.isAtTop? 'large' : 'small'
        },
        hamburguerClass () {
            return this.isAtTop? 'd-none': 'h1'
        },
        headerStyle () {
            return this.isAtTop? 'pt-2' : 'pt-1'
        }
    },
    data() {
        return {
            isAtTop: document.documentElement.scrollTop < 70
        }
    },
    methods: {
        scrollFunc() {
            this.isAtTop = document.documentElement.scrollTop < 70
        }
    },
    mounted() {
        // shrink on scroll
        window.addEventListener('scroll', debounce(this.scrollFunc, 100))
    }
}
</script>

<style scoped>
@font-face {
    font-family: TitleFont;
    src: url(../assets/fonts/AtariClassic.ttf);
}

#title-alt {
    font-family: TitleFont, monospace;
}

header {
    z-index: -2000;
}

#day-select-alt {
    width: auto;
    min-width: 3.5em;
}

#month-select-alt {
    width: auto;
    min-width: 6.5em;
}

#dummy-year {
    width: 10rem;
}

@media (max-width: 1200px) {
    #logo-alt.large {
        max-height: calc(1.575rem + 6.3vw);
    }

    #logo-alt.small {
        max-height: calc(1.375rem + 3.8vw);
    }
}

@media (min-width: 1201px) {
    #logo.small {
        max-height: calc(1.3rem + 2vw);
    }
}
</style>
