<template>
<div class="container">
    <div class="row mb-4 align-items-center">
        <div class="col-12">
            <ul class="nav h3">
                <li class="nav-item">
                    <router-link :to="{'name': 'home'}" class="nav-link pl-0">Início</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{'name': 'about'}" class="nav-link pl-0">Sobre</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{'name': 'api'}" class="nav-link pl-0">API</router-link>
                </li>
                <li class="nav-item">
                    <a class="nav-link active pl-0">Estatísticas</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-12">
            <h3 class="my-5">O Destaques do Passado em números</h3>
            <dl class="row">
                <dt class="col-sm-3">Total de artigos</dt>
                <dd class="col-sm-9"><span ref="total_articles"></span></dd>

                <dt class="col-sm-3 pl-sm-5">Com imagem</dt>
                <dd class="col-sm-9"><span ref="img_articles"></span></dd>

                <dt class="col-sm-3 pl-sm-5">Com <i>lead</i></dt>
                <dd class="col-sm-9"><span ref="lead_articles"></span></dd>

                <dt class="col-sm-3 pl-sm-5">Com corpo arquivado no <a href="https://arquivo.pt" target="_blank">Arquivo.pt</a></dt>
                <dd class="col-sm-9"><span ref="archived_articles"></span></dd>

                <dt class="col-sm-3 pt-4">Primeira notícia arquivada</dt>
                <dd class="col-sm-9 pt-4"><span ref="first_day"></span>/<span ref="first_month"></span>/<span ref="first_year"></span></dd>
            </dl>

            <h3 class="mt-5">O Destaques do Passado em gráficos</h3>
            <canvas class="my-5" ref="year-distribution"></canvas>
            <canvas class="my-5" ref="source-distribution"></canvas>
            <canvas class="my-5" ref="day-distribution"></canvas>
            <canvas class="my-5" ref="category-distribution"></canvas>
        </div>
    </div>
</div>
</template>

<script>
import store from '@/store/index'
import { CountUp } from 'countup.js' // countup installed as dependency because of an export bug in cdn version

export default {
    mounted () {
        // counters
        const countConfigs = {separator: ' ', decimal: ',', duration: 4}

        try {
            new CountUp(this.$refs.total_articles, 512123, countConfigs).start()
            new CountUp(this.$refs.img_articles, 103365, countConfigs).start()
            new CountUp(this.$refs.lead_articles, 208765, countConfigs).start()
            new CountUp(this.$refs.archived_articles, 408116, countConfigs).start()
            new CountUp(this.$refs.first_day, 11, {startVal: new Date().getDay(), duration: 4, formattingFn: (n) => n.toString().padStart(2, '0')}).start()
            new CountUp(this.$refs.first_month, 8, {startVal: new Date().getMonth()+1, duration: 4, formattingFn: (n) => n.toString().padStart(2, '0')}).start()
            new CountUp(this.$refs.first_year, 1996, {startVal: new Date().getFullYear(), duration: 4, useGrouping: false}).start()

        } catch (err) {
            this.$refs.total_articles.textContent = '512 123'
            this.$refs.img_articles.textContent = '103 365'
            this.$refs.lead_articles.textContent = '208 765'
            this.$refs.archived_articles.textContent = '408 116'
            this.$refs.first_day.textContent = '11'
            this.$refs.first_month.textContent = '8'
            this.$refs.first_year.textContent = '1996'
        }

        // graphs
        new Chart(this.$refs['year-distribution'].getContext('2d'), {
            type: 'line',
            data: {
                labels: ['1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016'],
                datasets: [{
                    label: 'Artigos',
                    data: [6,11,83,207,623,7266,1910,2874,16635,30622,17269,18803,3776,1612,46863,84463,47690,33083,59193,99256, 39878],
                    fill: true,
                    backgroundColor: 'rgba(36, 43, 74, 0.6)',
                    borderColor: 'rgba(36, 43, 74, 0.6)'
                }]
            },
            options: {
                responsive: true,
                title: {
                    display: true,
                    text: 'Distribuição de artigos por ano (1996-2016)'
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        display: true,
                        scaleLabel: {
                            display: false,
                            labelString: 'Ano'
                        }
                    }],
                    yAxes: [{
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Artigos'
                        }
                    }]
                }
            }
        })

        new Chart(this.$refs['source-distribution'].getContext('2d'), {
            type: 'pie',
            data: {
                datasets: [{
                    data: [129053, 75776, 58623, 42389, 35436, 28060, 21028, 16720, 11764, 93274],
                    backgroundColor: [
                        'rgb(36, 43, 74)',
                        'rgb(209, 0, 25)',
                        'rgb(255, 199, 10)',
                        'rgb(92, 158, 173)',
                        'rgb(224, 202, 60)',
                        'rgb(11, 110, 79)',
                        'rgb(255, 214, 186)',
                        'rgb(89, 165, 216)',
                        'rgb(255, 166, 48)',
                        'rgb(85, 134, 140)'
                    ]
                }],
                labels: [
                    'Diário Digital',
                    'Público',
                    'Diário de Notícias',
                    'TSF',
                    'Rádio Renascença',
                    'RTP',
                    'Expresso',
                    'Portugal Diário',
                    'AEIOU',
                    'Outros'
                ]
            },
            options: {
                responsive: true,
                title: {
                    display: true,
                    text: 'Distribuição de artigos por fonte'
                },
                legend: {
                    position: 'right'
                }
            }
        })

        new Chart(this.$refs['day-distribution'].getContext('2d'), {
            type: 'line',
            data: {
                labels: [
                    '1/1', '2/1', '3/1', '4/1', '5/1', '6/1', '7/1', '8/1', '9/1', '10/1', '11/1', '12/1', '13/1', '14/1', '15/1', '16/1', '17/1', '18/1', '19/1', '20/1', '21/1', '22/1', '23/1', '24/1', '25/1', '26/1', '27/1', '28/1', '29/1', '30/1', '31/1',
                    '1/2', '2/2', '3/2', '4/2', '5/2', '6/2', '7/2', '8/2', '9/2', '10/2', '11/2', '12/2', '13/2', '14/2', '15/2', '16/2', '17/2', '18/2', '19/2', '20/2', '21/2', '22/2', '23/2', '24/2', '25/2', '26/2', '27/2', '28/2', '29/2',
                    '1/3', '2/3', '3/3', '4/3', '5/3', '6/3', '7/3', '8/3', '9/3', '10/3', '11/3', '12/3', '13/3', '14/3', '15/3', '16/3', '17/3', '18/3', '19/3', '20/3', '21/3', '22/3', '23/3', '24/3', '25/3', '26/3', '27/3', '28/3', '29/3', '30/3', '31/3',
                    '1/4', '2/4', '3/4', '4/4', '5/4', '6/4', '7/4', '8/4', '9/4', '10/4', '11/4', '12/4', '13/4', '14/4', '15/4', '16/4', '17/4', '18/4', '19/4', '20/4', '21/4', '22/4', '23/4', '24/4', '25/4', '26/4', '27/4', '28/4', '29/4', '30/4',
                    '1/5', '2/5', '3/5', '4/5', '5/5', '6/5', '7/5', '8/5', '9/5', '10/5', '11/5', '12/5', '13/5', '14/5', '15/5', '16/5', '17/5', '18/5', '19/5', '20/5', '21/5', '22/5', '23/5', '24/5', '25/5', '26/5', '27/5', '28/5', '29/5', '30/5', '31/5',
                    '1/6', '2/6', '3/6', '4/6', '5/6', '6/6', '7/6', '8/6', '9/6', '10/6', '11/6', '12/6', '13/6', '14/6', '15/6', '16/6', '17/6', '18/6', '19/6', '20/6', '21/6', '22/6', '23/6', '24/6', '25/6', '26/6', '27/6', '28/6', '29/6', '30/6',
                    '1/7', '2/7', '3/7', '4/7', '5/7', '6/7', '7/7', '8/7', '9/7', '10/7', '11/7', '12/7', '13/7', '14/7', '15/7', '16/7', '17/7', '18/7', '19/7', '20/7', '21/7', '22/7', '23/7', '24/7', '25/7', '26/7', '27/7', '28/7', '29/7', '30/7', '31/7',
                    '1/8', '2/8', '3/8', '4/8', '5/8', '6/8', '7/8', '8/8', '9/8', '10/8', '11/8', '12/8', '13/8', '14/8', '15/8', '16/8', '17/8', '18/8', '19/8', '20/8', '21/8', '22/8', '23/8', '24/8', '25/8', '26/8', '27/8', '28/8', '29/8', '30/8', '31/8',
                    '1/9', '2/9', '3/9', '4/9', '5/9', '6/9', '7/9', '8/9', '9/9', '10/9', '11/9', '12/9', '13/9', '14/9', '15/9', '16/9', '17/9', '18/9', '19/9', '20/9', '21/9', '22/9', '23/9', '24/9', '25/9', '26/9', '27/9', '28/9', '29/9', '30/9',
                    '1/10', '2/10', '3/10', '4/10', '5/10', '6/10', '7/10', '8/10', '9/10', '10/10', '11/10', '12/10', '13/10', '14/10', '15/10', '16/10', '17/10', '18/10', '19/10', '20/10', '21/10', '22/10', '23/10', '24/10', '25/10', '26/10', '27/10', '28/10', '29/10', '30/10', '31/10',
                    '1/11', '2/11', '3/11', '4/11', '5/11', '6/11', '7/11', '8/11', '9/11', '10/11', '11/11', '12/11', '13/11', '14/11', '15/11', '16/11', '17/11', '18/11', '19/11', '20/11', '21/11', '22/11', '23/11', '24/11', '25/11', '26/11', '27/11', '28/11', '29/11', '30/11',
                    '1/12', '2/12', '3/12', '4/12', '5/12', '6/12', '7/12', '8/12', '9/12', '10/12', '11/12', '12/12', '13/12', '14/12', '15/12', '16/12', '17/12', '18/12', '19/12', '20/12', '21/12', '22/12', '23/12', '24/12', '25/12', '26/12', '27/12', '28/12', '29/12', '30/12', '31/12'
                ],
                datasets: [{
                    label: 'Artigos',
                    data: [1339, 965, 1215, 1217, 1418, 1302, 1239, 1069, 1171, 1645, 1636, 1459, 1346, 1393, 1605, 1214, 1669, 1678, 1836, 1247, 1348, 1547, 1692, 1280, 1237, 1710, 1432, 1250, 1761, 1244, 1530, 1318, 1521, 1321, 1711, 1280, 1256, 1280, 1320,
                        1505, 1336, 1274, 1515, 1261, 1328, 1294, 1198, 1361, 1233, 1146, 1141, 1484, 1553, 1249, 1252, 1461, 1278, 1481, 1205, 560, 1564, 1336, 1301, 1244, 1074, 1101, 1062, 1030, 1191, 1105, 1324, 1370, 1024, 1198, 1203, 1225, 1087, 1324, 1094,
                        1411, 1229, 1155, 1362, 1373, 1010, 1229, 1329, 1194, 1494, 1252, 1226, 1311, 886, 747, 926, 1398, 987, 687, 1241, 1008, 873, 699, 1075, 1171, 893, 862, 1046, 855, 913, 1113, 795, 977, 1176, 789, 893, 771, 759, 1155, 1111, 1296, 1306, 927,
                        1342, 1295, 1363, 1336, 1362, 1334, 1372, 1584, 1550, 1362, 1342, 1532, 1288, 1148, 1469, 1482, 1265, 1543, 1796, 1391, 1666, 1378, 1579, 1558, 1437, 1016, 1374, 1452, 1219, 1489, 1354, 1537, 1346, 1401, 1142, 1232, 1285, 1182, 1223, 1078,
                        1188, 1161, 874, 1343, 1477, 1330, 1148, 1185, 1134, 1100, 1284, 1430, 1414, 1356, 1216, 1148, 1162, 1014, 1298, 1259, 1862, 1451, 1096, 1369, 1210, 1243, 1644, 1096, 940, 1164, 1179, 1323, 990, 1332, 1301, 1451, 1155, 1072, 1278, 1431,
                        1352, 1057, 1246, 1166, 1162, 1104, 1524, 1343, 1120, 1949, 1423, 1288, 1756, 1402, 1475, 1677, 1587, 1486, 1545, 1767, 1273, 1828, 1368, 1403, 1816, 1763, 1547, 1515, 1455, 1802, 1759, 1624, 1442, 1829, 1684, 1669, 1451, 1444, 1676, 1849,
                        1879, 1734, 1737, 1414, 1690, 1748, 1318, 1519, 1791, 1614, 1684, 1710, 1403, 1353, 1730, 1522, 1759, 1805, 1309, 1632, 1907, 1361, 1542, 1772, 1647, 1662, 2244, 1595, 1399, 1761, 2190, 1728, 1914, 2063, 1391, 2078, 1773, 1628, 1558, 1681,
                        1735, 1609, 1652, 1566, 1771, 1764, 1686, 2128, 1709, 1486, 1578, 1689, 1973, 1965, 1946, 2128, 1533, 1961, 1836, 1807, 1750, 1900, 1661, 1487, 1602, 1708, 1828, 1601, 1643, 1662, 1781, 1485, 1594, 1308, 1800, 1752, 1449, 1253, 1565, 1402,
                        1471, 1322, 1233, 1288, 1366, 1516, 1587, 1693, 1439, 1472, 1296, 1285, 1358, 1435, 1683, 1366, 1297, 1554, 1345, 1439, 1350, 1344, 1664, 1399, 1393, 1436, 1527, 1455, 1776, 1657, 1318, 1320, 1098, 1337, 1200, 1192, 1158, 848, 1031, 1079,
                        1174, 1389, 1572, 1542],
                    fill: true,
                    backgroundColor: 'rgba(36, 43, 74, 0.6)',
                    borderColor: 'rgba(36, 43, 74, 0.6)'
                }]
            },
            options: {
                responsive: true,
                title: {
                    display: true,
                    text: 'Distribuição de artigos por dia'
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        display: true,
                        scaleLabel: {
                            display: false,
                            labelString: 'Dia'
                        }
                    }],
                    yAxes: [{
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Artigos'
                        }
                    }]
                },
                elements: {
                    point:{
                        radius: 0
                    }
                }
            }
        })

        new Chart(this.$refs['category-distribution'].getContext('2d'), {
            type: 'pie',
            data: {
                datasets: [{
                    data: [209935, 52918, 49619, 38056, 33759, 23285, 19000, 16530, 16197, 52824],
                    backgroundColor: [
                        'rgb(255, 199, 10)',
                        'rgb(89, 165, 216)',
                        'rgb(209, 0, 25)',
                        'rgb(36, 43, 74)',
                        'rgb(224, 202, 60)',
                        'rgb(92, 158, 173)',
                        'rgb(255, 166, 48)',
                        'rgb(11, 110, 79)',
                        'rgb(255, 214, 186)',
                        'rgb(85, 134, 140)'
                    ]
                }],
                labels: [
                    'Genérico',
                    'Desporto',
                    'Miscelânea',
                    'Economia',
                    'Mundo',
                    'Cultura',
                    'Sociedade',
                    'Entretenimento',
                    'Portugal',
                    'Outras categorias'
                ]
            },
            options: {
                responsive: true,
                title: {
                    display: true,
                    text: 'Distribuição de artigos por categoria'
                },
                legend: {
                    position: 'right'
                }
            }
        })
    },
    async beforeRouteEnter(to, from, next) {
        window.scrollTo(0,0)
        next()
    },
    async beforeRouteLeave(to, from, next) {
        await store.dispatch('setFetch', false)
        next()
    }
}

</script>

<style scoped>
.nav-link {
    font-family: Andada, serif;
}

.nav-link.active {
    color: black;
}
</style>
