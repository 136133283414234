import Vue from 'vue'
import Vuex from 'vuex'

import {requestData} from './ajax'
import {getRobotDate, currentDay, currentMonth, randomString} from '@/util'
import {selectArticles} from '@/article_selector'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        seed: null,
        rng: new Math.seedrandom(), // this is here to avoid possible errors if app created was executed after rng is needed

        // articles related
        articles: [],
        metadata: {
            categories: {},
            imgs: 0,
            snippets: 0,
            total: 0,
            years: []
        },
        selectedArticles: {
            main: [],
            latest: [],
            other: [],
            category: {}
        },

        // user inputs
        selectedDay: currentDay(),
        selectedMonth: currentMonth(),
        yearFilter: 'Todos',
        searchTerm: '',

        // ui
        animateOnEnter: true,
        animateOnLeave: true,
        isTransitioning: false,
        loaded: false,
        mountedMap: {
            main: false,
            latest: false,
            other: false,
            category: {}
        },

        // internal
        showTimeout: null,
        transitionTimeout: null,
        fetch: true
    },
    mutations: {
        SET_RNG(state, payload) {
            state.seed = payload !== null? payload : randomString(Math.random, 32).toLowerCase()
            state.rng = new Math.seedrandom(state.seed)
        },
        SET_ARTICLES(state, payload) {
            state.articles = payload.articles
            state.metadata = payload.metadata
            state.selectedArticles = payload.selectedArticles
        },

        SELECT_DATE(state, payload) {
            if ('day' in payload && payload['day'] !== null)
                state.selectedDay = payload.day

            if ('month' in payload && payload['month'] !== null)
                state.selectedMonth = payload.month
        },
        SET_YEAR_FILTER(state, payload) {
            state.yearFilter = payload
        },
        SET_SEARCH_TERM(state, payload) {
            state.searchTerm = payload
        },

        SET_ANIMATE(state, payload) {
            if ('enter' in payload)
                state.animateOnEnter = payload.enter

            if ('leave' in payload)
                state.animateOnEnter = payload.leave
        },
        SET_IS_TRANSITIONING(state, payload) {
            state.isTransitioning = payload
        },
        SET_LOADED(state, payload) {
            state.loaded = payload
        },
        RESET_MOUNTED_MAP(state, payload) {
            state.mountedMap = {
                main: false,
                latest: false,
                other: false,
                category: {}
            }

            for (let i = 0; i < payload.length; i++)
                state.mountedMap['category'][payload[i]] = false
        },
        SET_MOUNTED_MAP(state, payload) {
            if (payload.type === 'category') {
                state.mountedMap[payload.type][payload.value] = true
            } else {
                state.mountedMap[payload.type] = true
            }
        },

        SET_FETCH(state, payload) {
            state.fetch = payload
        }
    },
    actions: {
        async setRNG({ commit }, payload) {
            commit('SET_RNG', payload)
        },
        async selectDate({ commit }, payload) {
            commit('SELECT_DATE', payload)
        },
        async setYearFilter({ commit }, payload) {
            commit('SET_YEAR_FILTER', payload)
        },
        async setSearchTerm({ commit }, payload) {
            commit('SET_SEARCH_TERM', payload)
        },

        async setIsTransitioning({ commit }, payload) {
            commit('SET_IS_TRANSITIONING', payload)
        },
        async setFetch({ commit }, payload) {
            commit('SET_FETCH', payload)
        },
        async setMounted({ commit }, payload) {
            commit('SET_MOUNTED_MAP', payload)
        },

        async populateArticles({ commit, state, dispatch }, payload) {
            // TODO refresh doesnt always work
            const hasFetched = 'main' in state.selectedArticles && Object.keys(state.selectedArticles['main']).length > 0

            if (!state.fetch && hasFetched) {
                // allow fetch next time
                commit('SET_FETCH', true)
                commit('SET_ANIMATE', {enter: false})

                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                })

                return
            }

            NProgress.configure({showSpinner: false})
            NProgress.start()

            // start backend request ASAP
            const date = getRobotDate(state.selectedDay, state.selectedMonth)
            const requestPromise = requestData(date) // shall be awaited

            // if no payload provided animate by default
            if (!payload) {
                payload = {
                    animateOnEnter: true,
                    animateOnLeave: true
                }
            }

            // scroll window to top so user always sees main articles first
            // this also triggers the "isAtTop" watcher on the headers, otherwise we'd need to force it to re-evaluate
            // this was below the "commit('SET_IS_TRANSITIONING', true)" but a bug in firefox made a weird scroll effect
            if (document.documentElement.scrollTop > 0) {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                })
            }

            commit('SET_ANIMATE', {
                enter: payload.animateOnEnter,
                leave: payload.animateOnLeave
            })

            // hide old, if present
            if (hasFetched)
                commit('SET_IS_TRANSITIONING', true)

            // if its the first populate no need to wait for the hide transition to finish
            if (!hasFetched) {
                await dispatch('populateEnd', requestPromise)
            } else {
                if (state.showTimeout) {
                    clearTimeout(state.showTimeout)
                    state.showTimeout = null
                }

                if (state.transitionTimeout) {
                    clearTimeout(state.transitionTimeout)
                    state.transitionTimeout = null
                }

                state.showTimeout = setTimeout(async () => {
                    await dispatch('populateEnd', requestPromise)
                }, 800)
            }
        },
        async populateEnd({ commit, state, dispatch, getters }, payload) {
            // await for it if the promise hasn't returned
            payload = await payload
            NProgress.set(0.8)

            // clear year filter if needed (don't end up with invalid year)
            if (!payload.metadata.years.includes(state.yearFilter))
                await dispatch('setYearFilter', 'Todos')

            // select articles to show
            const selected = selectArticles(state.rng, payload.articles, payload.metadata, state.searchTerm, state.yearFilter !== 'Todos'? parseInt(this.state.yearFilter) : null)

            commit('SET_LOADED', false) // set load to false before replacing selected articles, so all elements are hidden
            commit('SET_ARTICLES', { articles: payload.articles, metadata: payload.metadata, selectedArticles: selected })
            commit('RESET_MOUNTED_MAP', getters.popularCategories) // map to indicate mounted elems
            commit('SET_LOADED', true) // now it can show

            // attempt to fix firefox bug
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })

            state.transitionTimeout = setTimeout(() => {
                dispatch('setIsTransitioning', false)
            }, 200)

            NProgress.done()
        }
    },
    getters: {
        years (state) {
            return state.metadata? state.metadata.years : []
        },
        selectedArticles (state) {
            return state.selectedArticles
        },

        popularCategories (state) {
            if (state.selectedArticles && state.selectedArticles.category) {
                // categories must have elements to be considered
                return Object.keys(state.selectedArticles.category).filter(e => state.selectedArticles['category'][e].length > 0)
            }

            return []
        },

        selectedDay (state) {
            return state.selectedDay
        },
        selectedMonth (state) {
            return state.selectedMonth
        },
        searchTerm (state) {
            return state.searchTerm
        },

        animateOnEnter (state) {
            return state.animateOnEnter
        },
        animateOnLeave (state) {
            return state.animateOnLeave
        },
        isTransitioning (state) {
            return state.isTransitioning
        },
        isLoaded (state) {
            return state.loaded
        }
    }
})
