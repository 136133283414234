import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'
import About from '@/views/About'
import API from '@/views/API'
import Stats from '@/views/Stats'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/sobre',
        name: 'about',
        component: About
    },
    {
        path: '/api',
        name: 'api',
        component: API
    },
    {
        path: '/estatisticas',
        name: 'stats',
        component: Stats
    },
    {
        path: '*',
        redirect: '/'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
