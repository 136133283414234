<template>
<article class="list-group-item flex-column align-items-start mb-2" :class="classes">
    <div v-if="article['pretitle'] || reservePretitleSpace" class="row no-gutters">
        <div class="col-12">
            <h6 v-if="article['pretitle']" aria-label="antetitulo">
                {{ article['pretitle'] }}
            </h6>
            <h6 v-else-if="reservePretitleSpace" class="d-none d-lg-block invisible" style="color: white">
                .
            </h6>
        </div>
    </div>
    <div class="row no-gutters">
        <div class="col-10">
            <h5 class="title mb-1">
                <a :href="articleUrl" target="_blank" class="clickable" :class="{'text-muted no-underline': !articleUrl}" aria-label="titulo" ref="article_title">
                    {{ article['title'] }}
                </a>
            </h5>

            <p class="small text-muted" aria-label="fonte-original">{{ article['source'] }}</p>
        </div>
        <div class="col-2 text-right">
            <div class="row no-gutters">
                <div class="col-12 text-muted small">
                    {{ article['year'] }}
                </div>
            </div>
            <div class="row no-gutters mt-1">
                <div class="col-12">
                    <a :href="article['arquivo_source_url']" target="_blank" class="text-muted no-underline" ref="sourceIcon">
                        <i class="fa fa-file-alt"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</article>
</template>

<script>
import article from '@/mixins/article'
import '@/assets/article_animations.css'

export default {
    name: 'SmallArticle',
    mixins: [
        article
    ],
    props: {
        reservePretitleSpace: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            enterAnimation: 'enter-animation-fast',
            leaveAnimation: 'leave-animation-fast'
        }
    },
    mounted() {
        $(this.$refs.sourceIcon.firstChild).popover({content: 'Fonte', trigger: 'hover focus'})
    },
    beforeDestroy() {
        $(this.$refs.sourceIcon.firstChild).popover('hide')
    }
}
</script>

<style scoped>
.list-group-item {
    z-index: 1000;
    border: 0;
    padding: 0;
}
</style>
