<template>
<v-select class="selector month-selector" :clearable="false" :searchable="allowSearch" :options="months" :value="months[selectedMonth]" :reduce="m => m.id" @input="changeMonth" v-on:search:focus="fixHighlight">
    <span slot="no-options">
        Insira um mês válido
    </span>
</v-select>
</template>

<script>
import 'vue-select/dist/vue-select.css'

import store from '@/store'
import {MONTH_DURATIONS, MONTHS} from '@/util'

export default {
    props: {
        allowSearch: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        selectedDay() {
            return store.state.selectedDay
        },
        selectedMonth() {
            return store.state.selectedMonth
        },
        months() {
            let list = []
            for (let i = 0; i < MONTHS.length; i++)
                list.push({label: MONTHS[i], id: i})
            return list
        },
    },
    methods: {
        async changeMonth(month) {
            // if selected day is too big for the month, select the last day of the new month
            let day = null
            if (this.selectedDay > MONTH_DURATIONS[month])
                day = MONTH_DURATIONS[month]

            await store.dispatch('selectDate', { month: parseInt(month), day: day })
            await store.dispatch('populateArticles')
        },
        fixHighlight () {
            this.$nextTick(() => {
                $('.month-selector .vs__dropdown-option.vs__dropdown-option--highlight').removeClass('vs__dropdown-option--highlight')
                $('.month-selector .vs__dropdown-option.vs__dropdown-option--selected').addClass('vs__dropdown-option--highlight')
            })
        }
    }
}
</script>

<style>
</style>
